import { ReactElement, useContext } from 'react'
import styled from 'styled-components'
import {
  CheckCircle as CheckCircleIcon,
  Store as StoreIcon,
  Block as BlockIcon,
  Warning,
  Delete,
} from '@mui/icons-material'
import { Button } from 'src/ui-components'
import { defaultTheme } from 'src/theme'
import {
  ProductLocationModel,
  ProductModel,
} from 'src/store/models/ProductModel'
import { useShoppingCart } from 'src/store/cart/ShoppingCart'
import { I18n, Translate } from 'src/i18n'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { QuantitySelector } from 'src/ui-components/products/QuantitySelector'
import { Vehicle } from 'src/store/models/Vehicles'
import { viewCartLink } from 'src/hooks/useCartProduct'
import { useUserStore } from 'src/store/user/UserStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { CartMode } from 'src/store/models/ShoppingCartModels'
import {
  NonCachedSearchType,
  SearchStoreContext,
} from 'src/store/search/SearchStore'
import miscellaneousVehicle from 'src/features/search/Results/utils/miscellaneousVehicle'
import { observer } from 'mobx-react-lite'
import { useCartLocationProduct } from 'src/hooks/useCartLocationProduct'

interface MultiLocationStoreInfoCardProps {
  location: ProductLocationModel
  selected?: boolean
  product: ProductModel
  vehicle: Vehicle
}

export const MultiLocationStoreInfoCard = observer(
  (props: MultiLocationStoreInfoCardProps): ReactElement => {
    const { location, selected, product, vehicle } = props
    const { disableStores, getDisableStore } = useShoppingCart()

    const cartProduct = useCartLocationProduct({
      product,
      vehicle,
      locationId: location?.locationId,
    })

    const {
      updateQuantity,
      inCart,
      cartProductsData,
      activeLocation,
      currentQty,
      handleAddVehiclePartToCartLocation,
      locationChangeTooltip,
      inCartText,
      disableAddToCart,
      setCurrentQty,
      setPriceSpinner,
      maxQuantity,
    } = cartProduct
    const { orderNumber, isStocked } = product
    const userStore = useUserStore()
    const cart = useShoppingCart()
    const uiStore = useUiStateStore()
    const { isCondensedView, displaySuccessNotification } = uiStore
    const { replacedLocationName, replacementLocationName } = cart
    const searchStore = useContext(SearchStoreContext)

    const globalQtyAvailable = product?.location
      ?.map((l) => l.qtyAvailable)
      .reduce((a, b) => a + b, 0)

    const inStockSomewhere = globalQtyAvailable > 0

    const orderIfNotAvailable =
      userStore?.preferences?.findit_orderIfNotAvail === 'true'

    const locationChangeTooltipContent = (
      <>
        <I18n name="quantitySelectedIsUnavailableAt" /> {replacedLocationName}
        . <I18n name="weUpdatedYourLocationTo" /> {replacementLocationName}.
      </>
    )
    let disableStore = disableStores && getDisableStore(location?.qtyAvailable)
    if (location?.qtyAvailable === 0 && location?.locType !== 'primary') {
      disableStore = true
    }
    if (location.locType === 'viewOnly') disableStore = true

    const isApplicationPart = (): boolean => {
      return !searchStore?.showPartsNotFitVehicle
    }

    const productRemovedFromCartMsg = Translate('partWasRemovedFromYourCart', [
      product.description || '',
    ])

    const handleAddToCart = () => {
      // handleCoverageMatch(orderNumber)
      // updateQuantity(currentQty)
      const isSingleCartMode = cart.getMode() === CartMode.SINGLE_VEHICLE
      // TODO: Check if the below 3 if conditions can be rewritten into if-else block
      if (
        isSingleCartMode ||
        (isApplicationPart() &&
          vehicle?.engine &&
          searchStore.lastNonCachedSearch !==
            NonCachedSearchType.searchByInterchange)
      ) {
        handleAddVehiclePartToCartLocation(
          vehicle?.engine ? vehicle : miscellaneousVehicle,
          location?.locationId,
          currentQty?.toString() || activeLocation?.minQty?.toString() || '1'
        )
        return
      }
      if (!inCart) {
        handleAddVehiclePartToCartLocation(
          vehicle?.engine ? vehicle : miscellaneousVehicle,
          location?.locationId,
          currentQty?.toString() || activeLocation?.minQty?.toString() || '1'
        )
      }
    }
    const getLocationDetails = (targetLocationId: string) => {
      const location = cartProductsData?.find(
        (loc) => loc.locationId === targetLocationId
      )
      return location
        ? { isInCart: true, qty: location?.quantityRequested }
        : { isInCart: false, qty: location?.quantityRequested }
    }
    return (
      <StoreWrapper>
        <div>
          <StoreName>{location.called}</StoreName>
          {!disableStore && (
            <div>
              <StoreInfoBoldText>
                {location.qtyAvailable > 0 && <StyledCheckCircleIcon />}
                {location.qtyAvailable === 0 && <StyledBlockIcon />}
                {location.qtyAvailable} <I18n name="available" />
              </StoreInfoBoldText>
              <StoreScheduleText>{location.schedule}</StoreScheduleText>
            </div>
          )}
          {disableStore && !selected && (
            <WarningWrapper>
              {location.qtyAvailable > 0 && <WarningIcon />}
              {location.qtyAvailable === 0 && <WarningNoQuantity />}
              <div>
                <StoreInfoBoldText>
                  {location.qtyAvailable} <I18n name="available" />
                </StoreInfoBoldText>
                {location.qtyAvailable > 0 && (
                  <LocationWarning>
                    <I18n name="selectAnotherLocation" />
                  </LocationWarning>
                )}
              </div>
            </WarningWrapper>
          )}
          {disableStore && selected && (
            <StoreInfoBoldText>
              {location.qtyAvailable > 0 && <StyledCheckCircleIcon />}
              {location.qtyAvailable} <I18n name="available" />
            </StoreInfoBoldText>
          )}
          {location.order && (
            <FlexDiv>
              <StoreInfoBoldText>
                <I18n name="order" />:
              </StoreInfoBoldText>
              {location.order}
            </FlexDiv>
          )}
          {location.estimatedDelivery && (
            <FlexDiv>
              <StoreInfoBoldText>
                <I18n name="estimatedDelivery" />:
              </StoreInfoBoldText>
              {location.estimatedDelivery}
            </FlexDiv>
          )}
          {location?.locType === 'primary' &&
            StoreInstances.uiStore.isDisplayMultiLocation() && (
              <FlexDiv>
                <StyledStoreIcon />
                <SpanLabel>
                  <I18n name="myStore"></I18n>
                </SpanLabel>
              </FlexDiv>
            )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'start',
          }}
        >
          <QuantitySelector
            orderIfNotAvailable={orderIfNotAvailable}
            onChange={async (newQty) => {
              setPriceSpinner(true)
              await updateQuantity(newQty)
            }}
            onRemove={() => undefined}
            minQty={activeLocation?.minQty}
            stepSize={activeLocation?.buyIncrement}
            initialValue={currentQty}
            onLocationChangeTooltip={locationChangeTooltip}
            locationChangeTooltipContent={locationChangeTooltipContent}
            locationScheduleContent={activeLocation?.schedule}
            orderNumber={orderNumber}
            isStoked={isStocked}
            value={(currentQty || activeLocation?.minQty || 1).toString()}
            setValue={(val) => setCurrentQty(Number(val))}
            isCondensedView={isCondensedView}
            disabled={disableStore}
            maxQty={maxQuantity}
          />
          {!getLocationDetails(location?.locationId)?.isInCart &&
            (inStockSomewhere || orderIfNotAvailable) && (
              <ButtonWrapper style={{ marginTop: isCondensedView && 0 }}>
                <AddButton
                  onClick={() => handleAddToCart()}
                  text="addToCart"
                  disabled={disableAddToCart || disableStore}
                  style={{
                    height: isCondensedView && '32px',
                    fontSize: isCondensedView && '14px',
                    marginTop: isCondensedView && '12px',
                  }}
                />
              </ButtonWrapper>
            )}

          {!inStockSomewhere && !orderIfNotAvailable && (
            <ButtonWrapper style={{ marginTop: isCondensedView && 0 }}>
              <AddButton
                text="addToCart"
                disabled
                style={{
                  height: isCondensedView && '32px',
                  fontSize: isCondensedView && '14px',
                  marginTop: isCondensedView && '12px',
                }}
              />
            </ButtonWrapper>
          )}
          {getLocationDetails(location?.locationId)?.isInCart && (
            <ButtonWrapper>
              <AddButton
                text={inCartText}
                variant="success"
                style={{
                  height: isCondensedView && '32px',
                  fontSize: isCondensedView && '14px',
                }}
              />
              <DeleteWrapper
                onClick={() => {
                  if (!inCart) return

                  cart.removeProductFromLocation(
                    product,
                    location?.locationId,
                    vehicle?.engine ? vehicle : miscellaneousVehicle
                  )
                  const selectedCurrentVehicle = vehicle
                  if (cartProductsData?.length <= 0) {
                    cart.removeProductFromVehicle(
                      selectedCurrentVehicle,
                      product
                    )
                  }
                  displaySuccessNotification(
                    productRemovedFromCartMsg,
                    viewCartLink
                  )
                }}
              >
                <DeleteButton />
              </DeleteWrapper>
            </ButtonWrapper>
          )}
        </div>
      </StoreWrapper>
    )
  }
)

const StyledStoreIcon = styled(StoreIcon)`
  color: #7ebd34;
`

const StyledBlockIcon = styled(BlockIcon)`
  color: #d9001b;
  margin-top: -5px;
  padding-right: 5px;
`

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  color: #7ebd34;
  margin-top: -5px;
  padding-right: 5px;
`

const FlexDiv = styled.div`
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
`

const SpanLabel = styled.div`
  padding-left: 0.3rem;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #050505;
`

const StoreInfoBoldText = styled.div`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #050505;
  padding-right: 5px;
`

const StoreName = styled.div`
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: #050505;
`

const StoreScheduleText = styled.div`
  font-family: Roboto, serif;
  font-style: normal;
  font-size: 15px;
  line-height: 30px;
  color: ${defaultTheme.colors.almostBlack};
`

const StoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid #d3d2cf;
  text-align: start;
`

const WarningWrapper = styled.div`
  display: flex;
`
const LocationWarning = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: ${(p) => p.theme.colors.text};
`
const WarningIcon = styled(Warning)`
  color: ${(p) => p.theme.colors.primary};
  width: 22px;
  height: 18.21px;
  margin-top: 1px;
  margin-right: 3px;
`

const WarningNoQuantity = styled(StyledBlockIcon)`
  padding: 0;
  margin: 0;
  margin-right: 3px;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 0;
  width: 168px;
`
const AddButton = styled(Button)`
  width: 168px;
  margin-left: 0px;
  margin-right: 0px;
`
const DeleteWrapper = styled.div`
  padding: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${defaultTheme.colors.siteBackground};
  cursor: pointer;
  border-radius: 3px;
  flex-basis: 48px;
`
const DeleteButton = styled(Delete)`
  color: ${defaultTheme.colors.greyDark};
`
