import { debounce } from 'lodash-es'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { getStartLocation } from 'src/features/search/Results/utils/LocationUtils'
import miscellaneousVehicle from 'src/features/search/Results/utils/miscellaneousVehicle'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { useShoppingCart } from 'src/store/cart/ShoppingCart'
import {
  allowOrderWithoutAvailableInventory,
  isVehicleComplete,
  productsMatch,
  selectLocationByQty,
  updatePartInfo,
} from 'src/store/cart/Utils'
import {
  ProductLocationModel,
  ProductModel,
} from 'src/store/models/ProductModel'
import { Vehicle } from 'src/store/models/Vehicles'
import {
  NonCachedSearchType,
  useSearchStore,
} from 'src/store/search/SearchStore'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { useUserStore } from 'src/store/user/UserStore'

import { LocationType } from 'src/helpers/locationType'
import { Translate } from 'src/i18n'
import history from '../helpers/history'
import { OrderSelection } from 'src/store/models/ShoppingCartModels'

export const viewCartLink = {
  text: 'view cart',
  action: (): void => {
    history.push('/cart')
  },
}

interface ICartProductProps {
  product: ProductModel
  vehicle?: Vehicle
  locationId?: string
}

export interface ICartProductData {
  updateQuantity: (qty: number) => Promise<void>
  numInCart: number
  setNumInCart: Dispatch<SetStateAction<number>>
  inCart: boolean
  activeLocation: ProductLocationModel
  currentQty: number
  setCurrentQty: Dispatch<SetStateAction<number>>
  setPriceSpinner?: Dispatch<SetStateAction<boolean>>
  showLocationSelector: () => void
  handleAddVehiclePartToCart: (vehicle: Vehicle) => void
  handleAddVehiclePartToCartLocation: (
    vehicle: Vehicle,
    locationId: string,
    qty?: string
  ) => void
  priceSpinner: boolean
  locationChangeTooltip: boolean
  initialSelectorQty: number
  inCartText: string
  disableAddToCart: boolean
  isZeroCost: boolean
  yourCostAfterPriceBreaks: () => number
  setActiveLocation: Dispatch<SetStateAction<ProductLocationModel>>
  product: ProductModel
  cartProductsData?: Array<OrderSelection>
}

export const useCartProduct = ({
  product,
  vehicle,
}: ICartProductProps): ICartProductData => {
  const [requestedSpecificStore, setRequestedSpecificStore] =
    useState<boolean>(false)
  const [priceSpinner, setPriceSpinner] = useState<boolean>(
    product.isLoadingPrices
  )
  const [locationChangeTooltip, setLocationChangeTooltip] =
    useState<boolean>(false)

  const productAddedToCartMsg = Translate('partWasAddedToYourCart', [
    product.description || '',
  ])

  const { perCarQty } = product

  const uiStore = useUiStateStore()
  const { displaySuccessNotification } = uiStore
  const cart = useShoppingCart()
  const searchStore = useSearchStore()
  const userStore = useUserStore()

  const { lastNonCachedSearch } = useSearchStore()

  const currentVehicle: Vehicle = useMemo<Vehicle>(() => {
    if (vehicle && isVehicleComplete(vehicle)) {
      return vehicle
    }

    return searchStore.currentVehicle?.engine
      ? searchStore.currentVehicle
      : miscellaneousVehicle
  }, [vehicle, searchStore.currentVehicle])

  const initialProductQty = currentVehicle?.engine
    ? cart.getTotalProductQuantityInCart(product, currentVehicle)
    : cart.getTotalProductQuantityInCart(product, miscellaneousVehicle)
  const [numInCart, setNumInCart] = useState(initialProductQty)

  useEffect(() => {
    if (StoreInstances.uiStore.isDisplayMultiLocation()) {
      setNumInCart(initialProductQty)
      setCurrentQty(initialProductQty)
    }
  }, [initialProductQty])

  const cartProductsData = currentVehicle?.engine
    ? cart?.getTotalProductsInCart(product, currentVehicle)
    : cart?.getTotalProductsInCart(product, miscellaneousVehicle)
  const inCart = initialProductQty > 0
  const locations = useMemo(() => {
    return product?.location ?? []
  }, [product?.location])

  const startLocation = useMemo(() => {
    if (inCart) {
      const parts = cart.vehicleProducts(currentVehicle)

      const part = (parts || []).find((part) => productsMatch(part, product))

      if (part) {
        return product.location.find(
          (location) =>
            part.orderSelections[0].locationId.toString() ===
            location.locationId.toString()
        )
      }
    }
    return searchStore.getUpdatedPartInfoDetails(product)?.updatedLocation &&
      !inCart
      ? searchStore.getUpdatedPartInfoDetails(product)?.updatedLocation
      : getStartLocation(locations, 1, inCart)
  }, [cart, currentVehicle, inCart, locations, product, searchStore])

  const [activeLocation, setActiveLocation] =
    useState<ProductLocationModel>(startLocation)

  // const productsFromLocation = cart.getProductsFromLocation(
  //   miscellaneousVehicle,
  //   activeLocation.locationId
  // )

  // const orderSelection = productsFromLocation
  //   ?.find((productFromLoc) => productsMatch(productFromLoc, product))
  //   .orderSelections?.find(
  //     (orderSelection) =>
  //       orderSelection?.locationId.toString() ===
  //       activeLocation.locationId.toString()
  //   )

  // const updateShippingCostEstimates = useUpdateShippingCostEstimates({
  //   product,
  //   orderSelection,
  //   vehicle: currentVehicle,
  // })

  // const updateOrderLocations = () => {
  //   if (
  //     cart.getProductsFromLocation(currentVehicle, activeLocation.locationId)
  //       .length === 0
  //   ) {
  //     cart.removeAnOrderLocation(currentVehicle, activeLocation.locationId)
  //   }
  // }
  // function roundYourCostToTwoDecimals(value) {
  //   return parseFloat(value.toFixed(2))
  // }

  const handleAutoChangeLocation = (newLocation: ProductLocationModel) => {
    setActiveLocation(newLocation)
    displaySuccessNotification('changedStoreDueToAvailability')
  }

  const setInitialValueToOne =
    userStore?.preferences?.display_perCarQtyOne === 'true'

  const initialPerCarQty = setInitialValueToOne ? 1 : perCarQty

  const displayMinQty =
    (activeLocation?.displayMinQty > activeLocation?.qtyAvailable
      ? activeLocation?.qtyAvailable
      : activeLocation?.displayMinQty) ?? 1

  const initialValueIfNotInCart =
    lastNonCachedSearch === NonCachedSearchType.searchByMultiPartsInquiry ||
    lastNonCachedSearch === NonCachedSearchType.searchBySpecificParts
      ? activeLocation?.buyQty
      : Math.max(
          1,
          displayMinQty,
          activeLocation?.buyIncrement || 0,
          initialPerCarQty
        )

  const initialSelectorQty: number =
    numInCart > 0
      ? numInCart
      : searchStore.getUpdatedPartInfoDetails(product)?.updatedQty > 0
        ? searchStore.getUpdatedPartInfoDetails(product)?.updatedQty
        : initialValueIfNotInCart
  const [currentQty, setCurrentQty] = useState<number>(initialSelectorQty)

  const inCartText = StoreInstances?.uiStore?.isDisplayMultiLocation()
    ? `${initialProductQty} ${Translate('inCart')}`
    : `${currentQty} ${Translate('inCart')}`

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateQuantity = useCallback(
    debounce(async (qty: number) => {
      setPriceSpinner(true)
      const prevQty = currentQty
      setCurrentQty(qty)
      const finalProductInfo = await updatePartInfo(
        product,
        qty,
        activeLocation
      )

      const orderIfNotAvailable =
        StoreInstances.userStore?.preferences?.findit_orderIfNotAvail === 'true'

      if (!orderIfNotAvailable && inCart) {
        const qtyAvailableAtLoc =
          finalProductInfo.location?.find((loc) => loc.isSelected)
            .qtyAvailable >= qty

        if (!qtyAvailableAtLoc) {
          setPriceSpinner(false)
          setCurrentQty(prevQty)
          uiStore.displayErrorNotification('productCantBeFulfilled')
          return
        }
      }

      // updating searched results with availability end point location update
      const searchedResultsParts =
        StoreInstances.searchStore.searchResults?.parts || []

      searchedResultsParts.forEach((part) => {
        if (productsMatch(part, finalProductInfo)) {
          part.location = finalProductInfo.location
        }
      })
      setPriceSpinner(false)

      // MPV3-3428 update location when product is in cart.
      const locationToSet = selectLocationByQty(finalProductInfo, qty, inCart)
      setActiveLocation(locationToSet)

      if (!inCart) {
        if (locationToSet.locationId !== activeLocation.locationId) {
          displaySuccessNotification('changedStoreDueToAvailability')
        }
      } else {
        try {
          await cart.setQtyAtLocationBulk([
            {
              product: finalProductInfo,
              locationId:
                locationToSet?.locationId || activeLocation?.locationId,
              quantity: qty,
              onLocationChange: (newLoc) => {
                // newLocationId = newLoc.locationId
                handleAutoChangeLocation(newLoc)
              },
              autoLocationChange:
                StoreInstances?.uiStore?.isDisplayMultiLocation()
                  ? false
                  : !requestedSpecificStore ||
                    !allowOrderWithoutAvailableInventory(activeLocation),
              vehicle: currentVehicle,
              setLocationChangeTooltip,
            },
          ])
          // await updateShippingCostEstimates(
          //   CartOperation.QTY_CHANGE,
          //   newLocationId
          // )
          // updateOrderLocations()
          /* eslint-disable  @typescript-eslint/no-explicit-any */
        } catch (e: any) {
          uiStore.displayErrorNotification(e.message)
          setCurrentQty(prevQty)
        }
      }
    }, 800),
    [inCart, activeLocation, currentVehicle]
  )

  const showLocationSelector = () => {
    uiStore.openLocationSelector({
      product,
      activeLocation,
      vehicle,
      onChangeLocation: async (selectedLocation) => {
        setActiveLocation(selectedLocation)
        if (!inCart || selectedLocation.qtyAvailable >= currentQty)
          uiStore.displaySuccessNotification('locationChangedTo', undefined, [
            selectedLocation.called,
          ])
        // if (
        //   selectedLocation.priceBreaks[0].unitCost !=
        //   activeLocation.priceBreaks[0].unitCost
        // ) {
        //   uiStore.displaySuccessNotification('costChangedFrom', undefined, [
        //     `$${roundYourCostToTwoDecimals(activeLocation?.priceBreaks[0]?.unitCost || 0)}`,
        //     `$${roundYourCostToTwoDecimals(selectedLocation?.priceBreaks[0]?.unitCost || 0)}`,
        //   ])
        // }
        setRequestedSpecificStore(true)
        try {
          if (inCart) {
            await cart.setQtyAtLocationBulk([
              {
                product,
                locationId: selectedLocation.locationId,
                autoLocationChange:
                  StoreInstances?.uiStore?.isDisplayMultiLocation()
                    ? false
                    : !allowOrderWithoutAvailableInventory(selectedLocation),
                quantity: currentQty,
                vehicle: currentVehicle,
                setLocationChangeTooltip,
                onLocationChange: (location) => {
                  setActiveLocation(location)
                  uiStore.displaySuccessNotification(
                    'locationChangedTo',
                    undefined,
                    [location.called]
                  )
                },
              },
            ])
            cart.save()
          }
        } catch (error: any) {
          uiStore.displayErrorNotification(error.message)
        }
      },
    })
  }

  const yourCostAfterPriceBreaks = (): number => {
    const priceBreaks = activeLocation?.priceBreaks || []
    if (priceBreaks.length > 0) {
      const lastPriceBreak = priceBreaks[priceBreaks.length - 1]
      for (const priceBreak of priceBreaks || []) {
        if (
          priceBreak?.minQty <= currentQty &&
          priceBreak?.maxQty >= currentQty
        ) {
          return priceBreak?.unitCost
        }
        // checking minQty for lastPriceBreak, as maxQty returns 0 for some parts
        if (lastPriceBreak?.minQty && currentQty >= lastPriceBreak?.minQty) {
          return lastPriceBreak?.unitCost
        }
      }
    }
    return activeLocation ? activeLocation.cost : 0
  }

  const isZeroCost =
    yourCostAfterPriceBreaks() === 0 && activeLocation?.coreCost === 0
  const disableAddToCart =
    isZeroCost ||
    priceSpinner ||
    activeLocation?.locType === LocationType.VIEW_ONLY

  const handleAddVehiclePartToCart = async (vehicle: Vehicle) => {
    cart.addVehicleToCart(vehicle)
    try {
      await cart.setQtyAtLocationBulk([
        {
          product,
          locationId: activeLocation?.locationId,
          quantity: currentQty,
          vehicle,
          setLocationChangeTooltip,
          autoLocationChange: StoreInstances?.uiStore?.isDisplayMultiLocation()
            ? false
            : !requestedSpecificStore ||
              !allowOrderWithoutAvailableInventory(activeLocation),
          onLocationChange: (location) => {
            setActiveLocation(location)
          },
        },
      ])
      setNumInCart(cart.getTotalProductQuantityInCart(product, vehicle))
      displaySuccessNotification(productAddedToCartMsg, viewCartLink)
    } catch (e: any) {
      uiStore.displayErrorNotification(e.message)
    }
  }
  const handleAddVehiclePartToCartLocation = async (
    vehicle: Vehicle,
    locationId: string,
    qty?: string
  ) => {
    cart.addVehicleToCart(vehicle)
    try {
      await cart.setQtyAtLocationBulk([
        {
          product,
          locationId,
          quantity: Number(qty),
          vehicle,
          setLocationChangeTooltip,
          autoLocationChange: StoreInstances?.uiStore?.isDisplayMultiLocation()
            ? false
            : !requestedSpecificStore ||
              !allowOrderWithoutAvailableInventory(activeLocation),
          onLocationChange: (location) => {
            setActiveLocation(location)
          },
        },
      ])
      setNumInCart(cart.getTotalProductQuantityInCart(product, vehicle))
      displaySuccessNotification(productAddedToCartMsg, viewCartLink)
    } catch (e: any) {
      uiStore.displayErrorNotification(e.message)
    }
  }

  return {
    updateQuantity,
    numInCart,
    setNumInCart,
    inCart,
    activeLocation,
    currentQty,
    setCurrentQty,
    showLocationSelector,
    handleAddVehiclePartToCart,
    handleAddVehiclePartToCartLocation,
    priceSpinner,
    locationChangeTooltip,
    initialSelectorQty,
    inCartText,
    disableAddToCart,
    isZeroCost,
    yourCostAfterPriceBreaks,
    setActiveLocation,
    setPriceSpinner,
    product,
    cartProductsData,
  }
}
