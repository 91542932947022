import { observer } from 'mobx-react-lite'
import { ReactElement, useCallback, useMemo, useRef, useState } from 'react'
import { useUiStateStore } from 'src/store/uiState/UiStateStore'
import { StoreInfoCard } from './StoreInfoCard'
import { MultiLocationStoreInfoCard } from './MultiLocationStoreInfoCard'
import { ProductLocationModel } from 'src/store/models/ProductModel'
import { StoreInstances } from 'src/store/StoreInstancesContainer'
import { I18n } from 'src/i18n'
import styled from 'styled-components'
import { breakpoints } from 'src/theme/breakpoints'
import { useShoppingCart } from 'src/store/cart/ShoppingCart'
import { CartVehicle } from 'src/store/models/ShoppingCartModels'
import miscellaneousVehicle, {
  isMiscellaneousVehicle,
} from 'src/features/search/Results/utils/miscellaneousVehicle'
import { makeNewCartVehicle } from 'src/store/cart/Utils'
import { ReactSelect } from 'src/ui-components/forms/ReactSelect'
import { Vehicle } from 'src/store/models/Vehicles'
import { NonCachedSearchType } from 'src/store/search/SearchStore'
import { SingleValueProps, components } from 'react-select'
import { Box, Drawer, Typography } from '@mui/material'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { create as createNiceModal, useModal } from '@ebay/nice-modal-react'
import { useClickOutsideDetector } from 'src/hooks'
import { defaultTheme } from 'src/theme'
import { zIndex } from 'src/theme/zIndex'
import CloseIcon from '@mui/icons-material/Close'
import { isMobileOnly } from 'react-device-detect'
import { useUserStore } from 'src/store/user/UserStore'
interface Option {
  label: string
  property: Vehicle
}

export const StoreSelector = createNiceModal(
  observer((): ReactElement | null => {
    const modal = useModal()
    const onClose = () => modal.remove()
    const show = modal.visible
    const ref = useRef<HTMLInputElement>(null)

    useClickOutsideDetector([ref], show, onClose)
    const uiStore = useUiStateStore()
    const userStore = useUserStore()
    const cart = useShoppingCart()
    const { product, vehicle } = uiStore.locationModalData ?? {}
    const [selectedCartVehicle, setSelectedCartVehicle] =
      useState<Option | null>(
        vehicle
          ? vehicle?.make?.value === 'MISCELLANEOUS'
            ? {
                label: 'Miscellaneous',
                property: vehicle,
              }
            : {
                label:
                  `${vehicle.year?.value || ''} ${vehicle.model?.value || ''}`.trim(),
                property: vehicle,
              }
          : {
              label: 'Miscellaneous',
              property: miscellaneousVehicle,
            }
      )

    const { vehicles, findCartVehicle } = useShoppingCart()

    const availableCartVehicles = useCallback((): CartVehicle[] => {
      if (!vehicles) return []

      const hasMiscellaneous = !!findCartVehicle(miscellaneousVehicle)

      return hasMiscellaneous
        ? vehicles
        : [...vehicles, makeNewCartVehicle(miscellaneousVehicle)]
    }, [vehicles, findCartVehicle])

    const cartVehicleData: Option[] = useMemo<Option[]>((): Option[] => {
      return (availableCartVehicles() || []).map(({ vehicle }) => {
        const isMiscellaneous = isMiscellaneousVehicle(vehicle)
        const vehicleName = isMiscellaneous
          ? 'Miscellaneous'
          : `${vehicle.year?.value || ''} ${vehicle.model?.value || ''}`.trim()

        return {
          label: vehicleName,
          property: vehicle,
        }
      })
    }, [availableCartVehicles])

    if (!product) {
      return null
    }

    const SingleValue: React.FC<SingleValueProps<unknown>> = (props) => {
      const { data } = props as { data: Option } // Type assertion to access Option properties
      return (
        <components.SingleValue {...props}>
          <Box display="flex" alignItems="center" padding="4px 8px">
            <ShoppingCartIcon
              fontSize="small"
              style={{
                marginRight: '8px',
                color: '#000',
              }}
            />
            <Typography variant="body2">{data.label}</Typography>
          </Box>
        </components.SingleValue>
      )
    }
    const activeLocation = uiStore?.locationModalData?.activeLocation
    const onChangeCartVechile = (value): void => {
      setSelectedCartVehicle(value)
    }

    return (
      <ResponsiveDrawer
        anchor="bottom"
        open={show}
        onClose={onClose}
        PaperProps={{
          square: false,
        }}
        style={{
          width: '100%',
        }}
      >
        <Container ref={ref}>
          <Title>
            <TitleHeader>
              <>
                <I18n name="changeLocation" />
                <StyledCloseIcon onClick={onClose} />
              </>
            </TitleHeader>
          </Title>
          {StoreInstances.uiStore.isDisplayMultiLocation() &&
            (Object.keys(vehicle)?.length !== 0 ||
              !cart?.isThereOnlyTheMiscellaneousCart()) &&
            (StoreInstances.searchStore.lastNonCachedSearch ===
              NonCachedSearchType.searchByInterchange ||
              StoreInstances.searchStore.lastNonCachedSearch ===
                NonCachedSearchType.searchByText) &&
            cartVehicleData.length > 1 &&
            userStore?.preferences?.cart_multiCart === 'true' && (
              <>
                <SelectCartMainHeading bold={true}>
                  <I18n name="selectCart" />
                  <AsterRisk />
                </SelectCartMainHeading>
                <CartSubHeading>
                  <I18n name="multipleOpenCartText" />
                </CartSubHeading>

                <OuterWrapper>
                  <ReactSelectWrapper>
                    <ReactSelect
                      value={selectedCartVehicle}
                      onChange={onChangeCartVechile}
                      options={cartVehicleData}
                      getOptionLabel={(option: unknown) =>
                        (option as Option).label
                      }
                      getOptionValue={(option: unknown) =>
                        (option as Option).label
                      }
                      placeholder="Select a Cart"
                      components={{ SingleValue }}
                      styles={{
                        control: (base) => ({
                          ...base,
                          height: '45px',
                          borderRadius: '4px',
                          paddingLeft: '8px',
                        }),
                        dropdownIndicator: (base) => ({
                          ...base,
                          padding: '8px',
                        }),
                        valueContainer: (base) => ({
                          ...base,
                          paddingLeft: '0',
                        }),
                      }}
                    />
                  </ReactSelectWrapper>
                </OuterWrapper>
                <hr />
              </>
            )}
          {!StoreInstances.uiStore.isDisplayMultiLocation() &&
            (product?.location || []).map((loc: ProductLocationModel) => (
              <StoreInfoCard
                key={loc.locationId}
                location={loc}
                selected={
                  activeLocation &&
                  Number(activeLocation.locationId) === Number(loc.locationId)
                }
              />
            ))}
          {StoreInstances.uiStore.isDisplayMultiLocation() &&
            (product?.location || []).map((loc: ProductLocationModel) => (
              <MultiLocationStoreInfoCard
                key={loc.locationId}
                location={loc}
                selected={
                  activeLocation &&
                  Number(activeLocation.locationId) === Number(loc.locationId)
                }
                product={product}
                vehicle={
                  StoreInstances.searchStore.lastNonCachedSearch !==
                  NonCachedSearchType.searchByInterchange
                    ? StoreInstances.searchStore.lastNonCachedSearch ===
                      NonCachedSearchType.searchByText
                      ? selectedCartVehicle?.property
                      : vehicle
                    : selectedCartVehicle?.property
                }
              />
            ))}
        </Container>
      </ResponsiveDrawer>
    )
  })
)

interface StyledTextProps {
  bold?: boolean
  underline?: boolean
  clickable?: boolean
}

const SelectCartMainHeading = styled.span<StyledTextProps>`
  font-family: Oswald;
  font-weight: ${(p) => (p.bold ? 'bold' : 'normal')};
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 24px;
  line-height: 34px;
  color: black;
  text-transform: none;

  @media screen and (max-width: ${breakpoints.tablet}px) {
    width: 70%;
    text-overflow: none;
    white-space: none;
  }
`
const CartSubHeading = styled.div<StyledTextProps>`
  font-family: Roboto;
  width:466px;
  margin-top:11px;
  font-weight: 400
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  line-height: 24px;
  color: black;
  text-transform: none;
`
const AsterRisk = styled.span`
  :before {
    color: red;
    content: '*';
  }
`
const OuterWrapper = styled.div`
  display: inline-block;
  position: relative;
  margin-top: 16px;
  margin-bottom: 34px;
  width: 430px;
  margin-right: 20px;
  @media screen and (max-width: ${breakpoints.tablet}px) {
    width: 300px;
  }
`
const ReactSelectWrapper = styled.div`
  width: 100%;
`
const ResponsiveDrawer = styled(Drawer)`
  /* padding: 28px 32px 37px; */
`
const Container = styled.div`
  position: relative;
  background-color: ${defaultTheme.colors.componentsBackground};
  z-index: ${zIndex.modal};
  padding: 28px 32px;
  overflow-y: auto;
  border-radius: 8px 8px 0px 0px;
  height: 70vh;

  @media screen and (min-width: ${breakpoints.laptop}px) {
    position: fixed;
    top: 0%;
    right: 0;
    border-radius: 0px;
    width: 520px;
    height: 100vh;
    padding: 35px 32px;
  }
`
const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  margin-top: 16px;
`
const Title = styled.div`
  font-family: Oswald, serif;
  font-style: normal;
  font-size: ${() => (isMobileOnly ? '20px' : '28px')};
  line-height: 48px;
  /* identical to box height, or 171% */
  /* Almost Black */
  color: ${defaultTheme.colors.almostBlack};
`

const TitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
`
